<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
        <AbstractTable :fields="fields" :items="alerts" :actions="actions" title="Alertas"
          :labelItemPerPage="'Alertas por página:'"
          :addBtn="{ enable: $store.state.isAdmin, text: 'Añadir una nueva alerta', event: 'addElement'}"
          @addElement="addAlert"
          @showDeleteModal="handleModal"
          :noResults="'No se han encontrado alertas'"
        />
        <DeleteModal :modalContent="modalContent" @delete="deleteAlertData(alertId)" :showModal="deleteModal" @closeModal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import "regenerator-runtime/runtime";
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import Loader from "@/components/Loader.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { getAlertsData, deleteAlert } from "@/services/alerts";
import { getImage } from '@/services/images';
import { generateField } from "@/utils/utils";
export default {
  name: "alertTable",
  components: { AbstractTable, DeleteModal, Loader },
  data() {
    return {
      loading: true,
      deleteModal: false,
      alert: {},
      alerts: [],
      alertId: 0,
      alertName: "",
      fields: [
        generateField("title", "Nombre de la alerta", {styleData: { textAlign: 'center'}}),
        generateField("description", "Descripción de la alerta"),
        generateField("alert_image", "Imagen", { type: "image", styleData: { textAlign: 'center'} }),
        generateField("actions", 'Acciones', { show: this.$store.state.isAdmin, sorter: false, filter: false })
      ],
      actions: [
        {
          key: "delete",
          label: "Eliminar",
          isAdmin: !this.$store.state.isAdmin,
          event: "showDeleteModal",
          classButton: "btn btn-delete btn-sm btn-pill",
        }
      ],
      modalContent: {
            title: 'Eliminar alerta',
            textBody: '',
            buttons: [
                {
                  textButton: 'Cerrar',
                  color: 'secondary',
                  event: 'closeModal'
                },
                {
                  textButton: 'Aceptar',
                  color: 'danger',
                  event: 'delete'
                },
            ]
      }
    };
  },
  created() {
      this.getAlerts();
    },
  methods: {
    /**
     * Función en la que se obtienen las alertas
     * 
     * @returns {object[]} Las alertas obtenidas.
     */
    getAlerts() {
      getAlertsData()
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
        .then((result) => {
          this.alerts = result;
          this.alerts.forEach((alert) => {
            getImage(alert.image)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                .then((imageUrl) => {
                alert.image = (alert.image) ? imageUrl : null;
            });
            alert.description = alert.description;
            this.loading = false;
          });
        })
    },
    /**
     * Este método se ejecuta al pulsar Añadir nueva alerta, redirige a la vista del formulario para crear una alerta
     */
    addAlert() {
      this.$router.push(`/alert/create/`);
    },
    /**
     * Método para controlar lo que se muestra en el Modal de Eliminar alerta.
     */
    handleModal(item) {
      this.alertId = item.id;
      this.modalContent.textBody = `¿Está seguro de que desea eliminar la alerta: <strong>"${item.title}</strong>"? Esta acción no se puede deshacer.`,
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará la alerta, mandando por la URL el id
     * 
     * @param {number} id - El id de la alerta a eliminar
     */
    deleteAlertData(id) {
      deleteAlert(id).then(() => {this.getAlerts()});
      this.deleteModal = false;
    },
    /**
     * Método para cerrar el modal.
     */
    closeModal(){
        this.deleteModal = false;
    }
  }
}
</script>
