import config from "@/config";

import { get, del, post } from "@/requests"

export async function getAlertsData() {
    return get(`${config.BACK_IP}/alert`)
}

export async function getAlertTypeData() {
    return get(`${config.BACK_IP}/alert_type`)
}

export async function createNewAlert(newAlert) {
    return post(`${config.BACK_IP}/alert`, newAlert)
}

export async function deleteAlert(id) {
    return del(`${config.BACK_IP}/alert/${id}`)
}