import config from "@/config";
import axios from 'axios';

export function getImage(image) {
    return new Promise((resolve, reject) => {
      const token = localStorage.token;
      axios.get(`${config.FRONT_MEDIA}/${image}`, {
        headers: { token },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const base64 = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const imageUrl = `data:image/png;base64,${base64}`;
        resolve(imageUrl);
      })
      .catch(error => {
        console.error('Error al obtener la imagen', error);
        reject(error);
      });
    });
  }